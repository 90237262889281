<template>
  <v-app class="page-blacklistsearch">
    <tev-navbar title="Blacklist Search" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12 sm12 class="mb-5">
          <v-container>
            <transition name="fade">
              <v-layout row wrap v-if="show">
                <v-flex xs12 sm8 offset-sm2 class="mb-5">
                  <v-layout wrap align-center justify-space-between row>
                    <h4 class="ma-0 d-flex">Search by: Company Name | Address;Zip | User Name </h4>
                  </v-layout>
                  <v-flex xs12>
                    <form v-on:submit.prevent="newSearch">
                      <v-text-field v-model="query" label="Search for a company name..." append-icon="search" @click:append="newSearch" :autofocus="$isDesktop()" single-line box></v-text-field>
                    </form>
                  </v-flex>
                  <div class="text-xs-center py-4" v-if="isLoading">
                    <v-progress-circular :size="50" color="primary" indeterminate/>
                  </div>
                  <v-card v-if="results&&!isLoading">
                    <v-card-title primary-title>
                      <div class="grey--text" v-if="results.statusTransunion=='FOUND' || results.statusEquifax=='FOUND'">
                        <h3 class="title red--text">FOUND</h3>
                      </div>
                      <div v-else>
                        <h3 class="title green--text">NOT FOUND</h3>
                      </div>
                    </v-card-title>
                    <v-card-title primary-title>
                      <div class="grey--text" v-if="results">
                        <pre>{{ results | pretty }}</pre>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-flex>
              </v-layout>
            </transition>
            <v-layout row wrap>
              <v-flex xs12 sm10 offset-sm2 class="mb-5">
                <h3 class="mb-3"><v-icon>cloud_upload</v-icon> Upload Blacklist DB</h3>
                <v-flex xs12 sm6>
                  <v-select v-model="source" :items="sources" label="DB Source" required box></v-select>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field type="password" v-model="password" label="Upload Password" required box></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-layout wrap align-center row>
                    <UploadButton large depressed class="px-0" color="secondary" v-bind:title="'Select CSV'" v-bind:multiple="false" v-bind:done="uploadDB" v-if="source&&password&&!isUploading"></UploadButton>
                    <v-btn large depressed class="px-0" color="gray" v-if="source&&password&&!isUploading" @click="source=null;password=null">Cancel</v-btn>
                  </v-layout>
                  </v-flex>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import config from '@/config'

import {
  mapGetters
} from 'vuex'
import axios from 'axios'
import UploadButton from '../../components/UploadButton'

export default {
  data() {
      return {
        show: false,
        isLoading: false,
        results: null,
        query: null,
        isUploading: false,
        sources: [
          'equifax',
          'transunion'
        ],
        password: null,
        source: null
      }
    },
    components: {
      UploadButton
    },
    filters: {
      pretty(value) {
        return JSON.stringify(value, null, 2);
      }
    },
    computed: {
      ...mapGetters({
        currentUser: 'currentUser'
      })
    },
    created() {
      this.checkCurrentLogin()
    },
    updated() {
      this.checkCurrentLogin()
    },
    methods: {
      checkCurrentLogin() {
        const now = Math.floor(Date.now() / 1000);
        if (!this.currentUser || !this.currentUser.isLoggedIn || !localStorage.token || now >= this.currentUser.expire) {
          this.$router.push('/login?redirect=' + this.$route.path)
        }
        this.currentUser.isCompliance = this.currentUser.auth.includes('ROLE_L_CM')
        this.currentUser.isManager = this.currentUser.auth.includes('ROLE_L_PM')
        this.currentUser.isEditor = this.currentUser.auth.includes('ROLE_PEND_PROP_ED')
      },
      alertError(message) {
        this.$events.$emit('showSnackbar', {
          message: message,
          color: 'red'
        });
      },
      newSearch() {
        const _this = this;
        const headers = {
          headers: {
            Authorization: 'Bearer ' + localStorage.token
          }
        };
        if (!this.query) {
          return
        }
        this.isLoading = true
        axios.get(`${config.userRestUrl}blacklist/check-in-blacklist?query=` + encodeURIComponent(this.query), headers)
          .then(response => {
            _this.results = response.data || [];
          })
          .catch(e => {
            // eslint-disable-next-line
            console.error(e)
          })
          .finally(() => (this.isLoading = false))
      },
      uploadDB(e) {
        const _this = this;
        const headers = {
          headers: {
            Authorization: 'Bearer ' + localStorage.token
          }
        };
        if(e.type != 'text/csv' && e.type != 'application/vnd.ms-excel') {
          return this.alertError('Error file type! The file must be CSV')
        }

        if((this.source == 'transunion'&&e.name.match(/equifax/g))
        ||(this.source == 'equifax'&&e.name.match(/transunion/g))) {
          return this.alertError('Error file name!')
        }

        this.isUploading = true
        axios.post(`${config.userRestUrl}blacklist/upload-csv`, {
          source: this.source,
          password: this.password,
          name: e.name,
          data: e.data,
          lastModified: e.file.lastModified
        }, headers)
          .then(response => {
            if(response.data.error) {
              _this.alertError(response.data.message)
            }
          })
          .catch(e => {
            // eslint-disable-next-line
            console.error(e)
          })
          .finally(() => (this.isUploading = false))
      }
    },
    mounted() {
      if (!this.currentUser.isCompliance) {
        return this.$router.push('/properties')
      }
      this.show = true;
    }
}
</script>
